import React from "react";

const Auflauf = () => {
  return (
    <section id="Auflauf" className="tab-panel">
      <h2 id="Auflauf">Mediterrane Gerichte</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <h4>122. Auberginen-Auflauf</h4>
            </td>
            <td className="preis preis--7">15,50€</td>
            <td className="preis preis--19">16,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit Tomatensauce, Reis, Schafskäse, Zwiebeln überbacken und Salat{" "}
            </td>
          </tr>
          <tr>
            <td>
              <h4>123. Auberginen-Auflauf</h4>
            </td>
            <td className="preis preis--7">15,90€</td>
            <td className="preis preis--19">17,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              Auberginenscheiben mit Hackfleisch, Schafskäse, Zwiebeln
              überbacken und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>124. Spaghetti Bolognese</h4>
            </td>
            <td className="preis preis--7">13,90€</td>
            <td className="preis preis--19">14,90€</td>
          </tr>
          <tr>
            <td className="untertext">auf griechische Art</td>
          </tr>
          <tr>
            <td>
              <h4>125. Spaghetti mit Tomatensauce</h4>
            </td>
            <td className="preis preis--7">13,90€</td>
            <td className="preis preis--19">14,90€</td>
          </tr>
          <tr>
            <td>
              <h4>126. Spaghetti mit Metaxa-Sauce</h4>
            </td>
            <td className="preis preis--7">13,90€</td>
            <td className="preis preis--19">14,90€</td>
          </tr>
          <tr>
            <td className="menu_info">
              <h5>auf Wunsch mit Edamer oder Fetakäse überbacken</h5>
            </td>
            <td className="preis preis--7">+2,50€</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default Auflauf;

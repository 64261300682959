import React from "react";

const Gyros = () => {
  return (
    <section id="Gyros" className="tab-panel">
      <h2>Gyros-Gerichte</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <h4>46. Gyros</h4>
            </td>
            <td className="preis preis--7">15,50€</td>
            <td className="preis preis--19">16,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit Pommes Frites, Reis, Zaziki und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>47. Gyros Überbacken </h4>
            </td>
            <td className="preis preis--7">16,90€</td>
            <td className="preis preis--19">17,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              Gyros mit Metaxa-Sauce und Edamer überbacken
              <br />
              dazu Pommes Frites, Reis und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>48. Gyrospfanne Krasi </h4>
            </td>
            <td className="preis preis--7">17,90€</td>
            <td className="preis preis--19">18,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              in pikanter Wein-Sauce mit frischem Champignons,
              <br />
              Pommes Frites und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>49. Gyrospfanne Metaxa</h4>
            </td>
            <td className="preis preis--7">17,90€</td>
            <td className="preis preis--19">18,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              in Metaxa-Sauce mit frischem Champignons
              <br />
              mit Pommes Frites und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>50. Gyros Spezial</h4>
            </td>
            <td className="preis preis--7">17,90€</td>
            <td className="preis preis--19">19,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit Metaxa-Sauce und Edamer Überbacken
              <br /> dazu Kretanische Kartoffeln und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>53. Pita Gyros</h4>
            </td>
            <td className="preis preis--7">6,50€</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default Gyros;

import React from "react";

const GriechischePlatten = () => {
  return (
    <section id="GriechischePlatten" className="tab-panel">
      <h2>Griechische Platten</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <h4>86. Ouzo-Platte für 2 Personen</h4>
            </td>
            <td className="preis preis--7">43,00€</td>
            <td className="preis preis--19">48,00€</td>
          </tr>
          <tr>
            <td className="untertext">
              Vorspeise:
              <br /> zwei Ouzo,Zaziki und gemischter Salat
              <br />
              Hauptspeise:
              <br />
              Gyros,zwei Suflaki, zwei Stück Rinderleber mit Beilagen
            </td>
          </tr>
          <tr>
            <td>
              <h4>87. Dionysos-Platte für 2 Personen</h4>
            </td>
            <td className="preis preis--7">49,50€</td>
            <td className="preis preis--19">55,00€</td>
          </tr>
          <tr>
            <td className="untertext">
              Vorspeise:
              <br /> Bauernsalat, Zaziki und 0,5l Wein
              <br />
              Hauptspeise:
              <br />
              Ein Schweinesteak, ein Spieß, zwei Suzuki,
              <br /> zwei Stück Rinderleber, Gyros und Beilagen
            </td>
          </tr>
          <tr>
            <td>
              <h4>88. Parthenon-Platte für vier Personen</h4>
            </td>
            <td className="preis preis--7">103,40€</td>
            <td className="preis preis--19">115,00€</td>
          </tr>
          <tr>
            <td className="untertext">
              Vorspeise:
              <br />
              Ein großer Bauernsalat und Zaziki
              <br />
              Hauptspeise:
              <br />
              Zwei Schweinesteaks, vier Suzuki, zwei Lammkoteletts,
              <br />
              zwei Rinderfilets, zwei Schweinefilets, Gyros und Beilagen
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default GriechischePlatten;

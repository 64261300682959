window.addEventListener("load", function () {
    var myTabs = document.querySelectorAll(".speisekartenContent--nav  li");

    function myTabClicks(tabClickEvent) {
        for (var i = 0; i < myTabs.length; i++) {
            myTabs[i].classList.remove("active");
        }
        var clickedTab = tabClickEvent.currentTarget;
        clickedTab.classList.add("active");
        tabClickEvent.preventDefault();
        var myContentPanes = document.querySelectorAll(".tab-panel");
        for (let i = 0; i < myContentPanes.length; i++) {
            myContentPanes[i].classList.remove("active");
        }
        var anchorReference = tabClickEvent.target;
        var activePaneId = anchorReference.getAttribute("href");
        var activePane = document.querySelector(activePaneId);
        activePane.classList.add("active");
    }

    for (let i = 0; i < myTabs.length; i++) {
        myTabs[i].addEventListener("click", myTabClicks)
    }


    // Define our viewportWidth variable
    var viewportWidth;

// Set/update the viewportWidth value
    var setViewportWidth = function () {
        viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    }

// Log the viewport width into the console
    var logWidth = function () {
        if (viewportWidth > 640) {
            console.log('Wide viewport');
        } else {
            console.log('Small viewport');
            var tabPanels = document.querySelectorAll('.tab-panel');

            tabPanels.forEach(function(tabPanel) {
                tabPanel.classList.add('open');
            });
        }
    }

// Set our initial width and log it
    setViewportWidth();
    logWidth();

// On resize events, recalculate and log
    window.addEventListener('resize', function () {
        setViewportWidth();
        logWidth();
    }, false);
});



import React from "react";
import VorspeisenKalt from "../menu/vorspeiseKalt";
import VorspeisenWarm from "../menu/vorspeiseWarm";
import Salate from "../menu/salate";
import International from "../menu/international";
import Gyros from "../menu/gyros";
import VomGrill from "../menu/vomGrill";
import GrillInternational from "../menu/grillInternational";
import GriechischePlatten from "../menu/griechischePlatten";
import Kindergerichte from "../menu/kindergerichte";
import Beilagen from "../menu/beilagen";
import Pfannengerichte from "../menu/pfannengerichte";
import Auflauf from "../menu/auflauf";
import Ueberbacken from "../menu/ueberbacken";
import Fisch from "../menu/fisch";
import Desserts from "../menu/desserts";

const MenuContent = () => {
  return (
    <main className="tab-content">
      {/* <Suppen /> */}
      <VorspeisenKalt />
      <VorspeisenWarm />
      <Salate />
      <International />
      <Gyros />
      <VomGrill />
      <GrillInternational />
      <GriechischePlatten />
      <Kindergerichte />
      <Beilagen />
      <Pfannengerichte />
      <Auflauf />
      <Ueberbacken />
      <Fisch />
      <Desserts />
    </main>
  );
};

export default MenuContent;

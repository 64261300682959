import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';

import Home from './sites/home.js';
import Speisekarte from './sites/speisekarte.js';
import Error from './sites/error.js';
import Impressum from './sites/impressum.js';



class App extends Component {
  render() {
    return (
<BrowserRouter>
 
<Switch>
<Route path='/' component={Home} exact />
<Route path='/speisekarte' component={Speisekarte}/>
<Route path='/impressum' component={Impressum}/>
<Route component={Error}/>
</Switch>

</BrowserRouter>
    );
  }
}

export default App;

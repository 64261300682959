import React from "react";
import { NavLink } from "react-router-dom";

import "../../scss/Navigation.scss";

const Navigation = () => {
  return (
    <nav className="navigation">
      <NavLink to="/">Home</NavLink>/
      <NavLink to="/speisekarte">Speisekarte</NavLink>
    </nav>
  );
};

export default Navigation;

import React from "react";

const Desserts = () => {
  return (
    <section id="Desserts" className="tab-panel">
      <h2>Desserts</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              {" "}
              <h4>148. Eine Kugel Eis mit Sahne*</h4>
            </td>
            <td className="preis">2,80€</td>
          </tr>
          <tr>
            <td>
              {" "}
              <h4>149. Zwei Kugeln Eis mit Sahne*</h4>
            </td>
            <td className="preis">4,30€</td>
          </tr>
          <tr>
            <td>
              {" "}
              <h4>150. Gemischtes Eis</h4>
            </td>
            <td className="preis">6,20€</td>
          </tr>
          <tr>
            <td className="untertext">vier Kugeln</td>
          </tr>
          <tr>
            <td>
              {" "}
              <h4>152. Griechischer Sahnejoghurt</h4>
            </td>
            <td className="preis">4,10€</td>
          </tr>
          <tr>
            <td className="untertext">mit Honig und Nüssen</td>
          </tr>
          <tr>
            <td>
              {" "}
              <h4>153. Panna Cotta</h4>
            </td>
            <td className="preis">3,90€</td>
          </tr>
          <tr>
            <td className="menu_info">
              <h5>Auf Wunsch mit verschiedenen Sirup-Sorten und Sahne</h5>
            </td>
          </tr>
          <tr>
            <td className="untertext"></td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default Desserts;

import React from "react";

const VorspeisenWarm = () => {
  return (
    <section id="WarmeVorspeisen" className="tab-panel">
      <h2>Warme Vorspeisen</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <h4>17. Flogeres </h4>
            </td>
            <td className="preis preis--7">5,50€</td>
            <td className="preis preis--19">5,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              Blätterteigröllchen gefüllt mit Schafskäse
            </td>
          </tr>
          <tr>
            <td>
              <h4>18. Panierter Saganaki </h4>
            </td>
            <td className="preis preis--7">8,50€</td>
            <td className="preis preis--19">9,30€</td>
          </tr>
          <tr>
            <td className="untertext">
              panierter Schafskäse auf Salat mit Tomate
            </td>
          </tr>
          <tr>
            <td>
              <h4>19. Gebackener Saganaki </h4>
            </td>
            <td className="preis preis--7">8,50€</td>
            <td className="preis preis--19">9,30€</td>
          </tr>
          <tr>
            <td className="untertext">
              zart gebackener Schafskäse in
              <br />
              einer pikanten Tomatensauce
            </td>
          </tr>
          <tr>
            <td>
              <h4>22. gefüllte Champignons</h4>
            </td>
            <td className="preis preis--7">10,00€</td>
            <td className="preis preis--19">10,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              gefüllt nach griechischer Bauernart mit Käse überbacken
            </td>
          </tr>
          {/* <tr>
            <td>
              <h4>23. Scampi aus der Pfanne</h4>
            </td>
            <td className="preis preis--7">12,40€</td>
          </tr>
          <tr>
            <td className="untertext">
              vier Riesengarnelen in Olivenöl und einem Hauch
              <br />
              Knoblauch gebraten, dazu eine Kugel Tarama
            </td>
          </tr> */}
          <tr>
            <td className="menu_info">
              <h5>Zu allen Vorspeisen reichen wir geröstetes Knoblauchbrot</h5>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default VorspeisenWarm;

import React from 'react';

import '../../scss/Header.scss';
import logo from '../../img/logo.svg';
import Navigation from './navigation';


const Header = () =>
    <header className='App__header--content'>
        <div><a href="/"><img src={logo} alt="Restaurant El Greco" /></a></div>
        <Navigation></Navigation>
    </header>

export default Header;
import React from "react";

const Fisch = () => {
  return (
    <section id="Fisch" className="tab-panel">
      <h2>Mehr aus dem Meer</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <h4>139. Calamares</h4>
            </td>
            <td className="preis preis--7">18,90€</td>
            <td className="preis preis--19">19,90€</td>
          </tr>
          <tr>
            <td className="untertext"></td>
          </tr>
          <tr>
            <td>
              <h4>140. Seezungenfilet</h4>
            </td>
            <td className="preis preis--7">19,90€</td>
            <td className="preis preis--19">20,90€</td>
          </tr>
          <tr>
            <td className="untertext"></td>
          </tr>
          <tr>
            <td>
              <h4>141. Scampi</h4>
            </td>
            <td className="preis preis--7">21,90€</td>
            <td className="preis preis--19">22,90€</td>
          </tr>
          <tr>
            <td className="untertext"></td>
          </tr>
          <tr>
            <td className="menu_info">
              <h5>dazu Butterreis, Broccoli, Kartoffeln, Tarama und Salat</h5>
            </td>
          </tr>
          <tr>
            <td className="untertext"></td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default Fisch;

import React, { Fragment } from "react";
import Header from "./components/header";
import Footer from "./components/footer";

const Error = () => {
  return (
    <Fragment>
      <Header></Header>
      <main className="error-page">
        <div className="textElement">
          <h1>404 - Seite nicht gefunden</h1>
        </div>
      </main>
      <Footer></Footer>
    </Fragment>
  );
};

export default Error;

import React from "react";

const Ueberbacken = () => {
  return (
    <section id="Ueberbacken" className="tab-panel">
      <h2>Überbacken</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <h4>131. Suflaki</h4>
            </td>
            <td className="preis preis--7">18,90€</td>
            <td className="preis preis--19">19,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              zwei Fleischspieße mit Metaxa-Sauce
              <br />
              und Edamer überbacken
            </td>
          </tr>
          <tr>
            <td>
              <h4>132. Suzuki</h4>
            </td>
            <td className="preis preis--7">18,50€</td>
            <td className="preis preis--19">19,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              drei Mettröllchen mit Metaxa-Sauce
              <br />
              und Edamer überbacken
            </td>
          </tr>
          <tr>
            <td>
              <h4>133. Bifteki</h4>
            </td>
            <td className="preis preis--7">19,50€</td>
            <td className="preis preis--19">20,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit Metaxa-Sauce und Edamer überbacken
            </td>
          </tr>
          <tr>
            <td>
              <h4>134. Schweinefilet</h4>
            </td>
            <td className="preis preis--7">20,70€</td>
            <td className="preis preis--19">21,70€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit Metaxa-Sauce und Edamer überbacken
            </td>
          </tr>
          <tr>
            <td>
              <h4>135. Putenbrustfilet</h4>
            </td>
            <td className="preis preis--7">18,90€</td>
            <td className="preis preis--19">19,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit Metaxa-Sauce und Edamer überbacken
            </td>
          </tr>
          <tr>
            <td className="menu_info">
              <h5>Beilagen: Pommes und Reis</h5>
            </td>
          </tr>
          <tr>
            <td className="untertext">
              <h5>mit Kretanischen Kartoffeln</h5>
            </td>
            <td className="preis preis--7">+2,50€</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default Ueberbacken;

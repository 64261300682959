import React from "react";

const Kindergerichte = () => {
  return (
    <section id="Kindergerichte" className="tab-panel">
      <h2>Kindergerichte</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <h4>93. Schneewittchen *</h4>
            </td>
            <td className="preis preis--7">13,50€</td>
            <td className="preis preis--19">13,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              Gyros mit Pommes Frites und Salatbeilage
            </td>
          </tr>
          <tr>
            <td>
              <h4>94. Die Schöne und das Biest *</h4>
            </td>
            <td className="preis preis--7">13,50€</td>
            <td className="preis preis--19">13,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              kleines Steak mit Pommes Frites und Salatbeilage
            </td>
          </tr>
          <tr>
            <td>
              <h4>95. Pinocchio *</h4>
            </td>
            <td className="preis preis--7">13,50€</td>
            <td className="preis preis--19">13,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              ein Fleischspieß mit Pommes Frites und Salatbeilage
            </td>
          </tr>
          <tr>
            <td>
              <h4>96. Dornröschen *</h4>
            </td>
            <td className="preis preis--7">13,50€</td>
            <td className="preis preis--19">13,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              zwei Suzuki(Mettröllchen) mit Pommes Frites
              <br /> und Salatbeilage
            </td>
          </tr>
          <tr>
            <td>
              <h4>97. Rapunzel *</h4>
            </td>
            <td className="preis preis--7">13,50€</td>
            <td className="preis preis--19">13,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              Putenbrustfilet mit Pommes Frites und Salatbeilage
            </td>
          </tr>
          <tr>
            <td className="menu_info">
              <h5>
                * die gekennzeichneten Gerichte werden mit
                <br />
                der hausgemachten Metaxa-Sauce serviert
              </h5>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default Kindergerichte;

import React from "react";

import "../../scss/Footer.scss";

const Footer = () => {
  return (
    <footer className="App__footer">
      <section className="App__footer--first textElement">
        <div>
          <h3>Kontakt</h3>
          <p>Restaurant El Greco</p>
          <p>
            Berliner Str. 49
            <br />
            38165 Lehre
          </p>
          <p>
            <a href="tel:+495308961366">05308 / 96 13 66</a>
          </p>
        </div>
        <div>
          <h3>Öffnungszeiten</h3>
          <p>
            <span>täglich</span>
            <br />
            17:00 bis 21:30 Uhr
            <br />
          </p>
          <p>
            <span>
              Samstag und Sonntag zusätzlich <br />
              11:30 Uhr bis 14:30 Uhr
            </span>
            <br />
            <strong>NEU: </strong>
            Montag &<strong> Dienstag</strong> Ruhetag
            <br />
          </p>
        </div>
      </section>
      <section class="App__footer--last textElement">
        <p>
          <a
            href="https://www.facebook.com/ElGrecoLehre/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
          <a
            href="https://www.instagram.com/elgreco.lehre/?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            - Instagram -
          </a>
          <a href="/impressum"> Impressum</a>
        </p>
      </section>
    </footer>
  );
};

export default Footer;

import React from 'react';
import Swiper from 'react-id-swiper';

import '../../scss/swiper.scss';
import '../../scss/Slider.scss';

import neubau2 from '../../img/slider/neubau2.jpg';
import neubau from '../../img/slider/neubau.jpg';
import family from '../../img/slider/family.jpg';
import sokrates from '../../img/slider/sokrates.jpg';
import altbau from '../../img/slider/altbau.jpg';


class Slider extends React.Component {
  render() {
    const params = {
      effect: 'fade',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      spaceBetween: 30
    }

    return (
      <Swiper {...params}>
        <div><img src={neubau2} alt="Neubau: Ansicht zum Tresen" /></div>
        <div><img src={neubau} alt="Blick von Innen nach Außen" /></div>
        <div><img src={sokrates} alt="Sokrates Teller: Gyros mit Leber" /></div>
        <div><img src={altbau} alt="Der Altbau vom Restaurant El Greco" /></div>
        <div><img src={family} alt="Die Familie Karalis" /></div>
      </Swiper>
    )
  }
}

export default Slider;




import React from "react";

const International = () => {
  return (
    <section id="International" className="tab-panel">
      <h2>Internationale Küche</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <h4>38. Jägerschnitzel "El Greco"</h4>
            </td>
            <td className="preis preis--7">16,90€</td>
            <td className="preis preis--19">17,90€</td>
          </tr>
          <tr>
            <td className="untertext">mit Pommes Frites und Salat</td>
          </tr>
          <tr>
            <td>
              <h4>39. Cordon Bleu </h4>
            </td>
            <td className="preis preis--7">16,90€</td>
            <td className="preis preis--19">17,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit Sauce Hollandaise, Pommes Frites und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>40. Lammfleisch </h4>
            </td>
            <td className="preis preis--7">18,90€</td>
            <td className="preis preis--19">19,90€</td>
          </tr>
          <tr>
            <td className="untertext">mit grünen Bohnen und Salat</td>
          </tr>
          <tr>
            <td>
              <h4>41. Lammfleisch </h4>
            </td>
            <td className="preis preis--7">18,90€</td>
            <td className="preis preis--19">19,90€</td>
          </tr>
          <tr>
            <td className="untertext">mit Spaghetti und Salat</td>
          </tr>
          <tr>
            <td>
              <h4>42. Schweinefilet-Medaillons</h4>
            </td>
            <td className="preis preis--7">19,50€</td>
            <td className="preis preis--19">20,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit Ananas und Edamer überbacken dazu
              <br />
              Kretanische Kartoffeln und Salat
            </td>
          </tr>
          {/* <tr>
            <td>
              <h4>43. Gemüseplatte</h4>
            </td>
            <td className="preis preis--7">17,50€</td>
          </tr>
          <tr>
            <td className="untertext">mit frischem Gemüse der Saison</td>
          </tr> */}
        </tbody>
      </table>
    </section>
  );
};

export default International;

import React, { Fragment } from "react";
import Header from "./components/header";
import MenuNavigation from "./components/menu_navigation";
import MenuContent from "./components/menu_content";
import Footer from "./components/footer";
import "../scss/Speisekarte.scss";
import "../js/tabfunction.js";
import image from "../img/oliven_und_oel.png";
import InfoBlock from "./components/infoBlock.js";

const Speisekarte = () => {
  return (
    <Fragment>
      <Header></Header>
      <section className="textElement">
        <h1>Speisekarte</h1>
        <div class="speisekarte__teaser-container">
          <p>
            Was auf unseren Tisch kommt, hat nicht nur die verschiedenen
            Qualitätsprüfungen bei den Erzeugern bestanden, sondern musste auch
            unsere kritischen Geruchs- und Geschmacksprüfungen überstehen.
          </p>
          <div className="speisekarte__teaser-lower-part">
            <InfoBlock>
              <p>
                Seit dem 1. Januar 2024 gelten zwei Preisklassen: Für
                Außerhauskäufe gelten Preise mit einer Mehrwertsteuer von 7%,
                während für Inhauskäufe eine Mehrwertsteuer von 19% berechnet
                wird.
              </p>
            </InfoBlock>
            <div className="speisekarte__image-wrapper">
              <img
                class="speisekarte__image"
                src={image}
                alt="Eine Zeichnung einer Flasche mit Olivenöl und Oliven"
              />
            </div>
          </div>
        </div>
      </section>
      <main className="container--tabs speisekartenContent">
        <MenuNavigation />
        <MenuContent />
      </main>
      <div className="disclaimer">
        Preise mit <strong>AH</strong> Kennzeichen nur für den
        Außer-Haus-Verkauf bestimmt.
      </div>
      <Footer></Footer>
    </Fragment>
  );
};

export default Speisekarte;

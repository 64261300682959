import React from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import Slider from "./components/slider";
import "../index.scss";

const Home = () => {
  return (
    <>
      <Header></Header>
      <main>
        <Slider></Slider>
        <div className="textElement">
          <h1>Restaurant El Greco in Lehre</h1>
          <h2>
            Griechisches Restaurant mit
            <br /> leckeren Speißen & herzlichem Service
          </h2>
          <p>
            Wir, die Familie Karalis im Restaurant El Greco in Lehre, freuen
            uns, Ihre Zeit mit Freunden und Familie verschönern zu können.
            Genießen Sie unsere griechischen Köstlichkeiten umgeben von
            familärer Gastfreundschaft.Bei uns ist für jeden etwas dabei und wir
            freuen uns, Sie als Gast in unserem Hause begrüßen zu dürfen!
          </p>

          <p>
            <strong>Öffnungszeiten:</strong>
            <br />
            täglich
            <br />
            17:00 bis 21:30 Uhr
            <br />
            Samstag und Sonntag zusätzlich
            <br />
            11:30 Uhr bis 14:30 Uhr
            <br />
            <strong>NEU: </strong>
            Montag &<strong> Dienstag</strong> Ruhetag
            <br />
            <br />
            Ihr Restaurant El Greco Team
          </p>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};

export default Home;

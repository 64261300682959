import React from "react";

const Salate = () => {
  return (
    <section id="Salate" className="tab-panel">
      <h2>Salate</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <h4>28. Bauernsalat </h4>
            </td>
            <td className="preis preis--7">13,50€</td>
            <td className="preis preis--19">14,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              Eisbergsalat, Tomaten, Gurken, Paprika,
              <br />
              Oliven, Peperoni und Schafskäse
            </td>
          </tr>
          <tr>
            <td>
              <h4>29. Thunfischsalat </h4>
            </td>
            <td className="preis preis--7">14,50€</td>
            <td className="preis preis--19">15,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              Eisbergsalat mit Thunfisch, Tomaten, Schafskäse,
              <br />
              Zwiebeln, Paprika und Oliven
            </td>
          </tr>
          <tr>
            <td>
              <h4>31. Valentinsalat</h4>
            </td>
            <td className="preis preis--7">15,50€</td>
            <td className="preis preis--19">16,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              Ei, Schinken, Thunfisch, Zwiebeln, Mais, Oliven,
              <br />
              und Schafskäse mit Joghurt-Dressing
            </td>
          </tr>
          <tr>
            <td className="menu_info">
              <h5>Zu allen Salaten gibt es geröstetes Knolauchbrot dazu</h5>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default Salate;

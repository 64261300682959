import React from "react";

const myTabs = document.querySelectorAll(".speisekartenContent--nav  li");

const MenuNavigation = () => {
  function handleClick(e) {
    //e.preventDefault();
    for (var i = 0; i < myTabs.length; i++) {
      myTabs[i].classList.remove("active");
    }
    var clickedTab = e.currentTarget;
    clickedTab.classList.add("active");
    e.preventDefault();
    var myContentPanes = document.querySelectorAll(".tab-panel");
    for (let i = 0; i < myContentPanes.length; i++) {
      myContentPanes[i].classList.remove("active");
    }
    var anchorReference = e.target;
    var activePaneId = anchorReference.getAttribute("href");
    var activePane = document.querySelector(activePaneId);
    activePane.classList.add("active");
  }

  function displayKategories(e) {
    e.preventDefault();
    const NavElement = document.querySelector(".nav-tabs");

    if (NavElement.classList.contains("nav-tabs--displayed")) {
      NavElement.classList.remove("nav-tabs--displayed");
      NavElement.classList.add("nav-tabs--hidden");
    } else {
      NavElement.classList.add("nav-tabs--displayed");
      NavElement.classList.remove("nav-tabs--hidden");
    }
  }

  return (
    <aside className="speisekartenContent--nav nav-tabs nav-tabs--hidden">
      <div
        className="speisekartenContent--nav--mobileOnly speisekartenContent--nav--mobileOnly--displayed"
        onClick={displayKategories}
      >
        <h2>Kategorien</h2>
      </div>
      <ul className="speisekartenContent--nav-ul">
        {/* <li className="active" onClick={handleClick}>
          <a href="#Suppen">Suppen</a>
        </li> */}
        <li className="active" onClick={handleClick}>
          <a href="#KalteVorspeisen">Kalte Vorspeisen</a>
        </li>
        <li onClick={handleClick}>
          <a href="#WarmeVorspeisen">Warme Vorspeisen</a>
        </li>
        <li onClick={handleClick}>
          <a href="#Salate">Salate</a>
        </li>
        <li onClick={handleClick}>
          <a href="#International">International</a>
        </li>
        <li onClick={handleClick}>
          <a href="#Gyros">Gyros-Gerichte</a>
        </li>
        <li onClick={handleClick}>
          <a href="#VomGrill">Griechische Grill-küche</a>
        </li>
        <li onClick={handleClick}>
          <a href="#GrillInternational">Grill International</a>
        </li>
        <li onClick={handleClick}>
          <a href="#GriechischePlatten">Griechische Platten</a>
        </li>
        <li onClick={handleClick}>
          <a href="#Kindergerichte">Kindergerichte</a>
        </li>
        <li onClick={handleClick}>
          <a href="#Beilagen">Beilagen</a>
        </li>
        <li onClick={handleClick}>
          <a href="#Pfannengerichte">Lockrufe aus der Pfanne</a>
        </li>
        <li onClick={handleClick}>
          <a href="#Auflauf">Auflauf</a>
        </li>
        <li onClick={handleClick}>
          <a href="#Ueberbacken">Überbacken</a>
        </li>
        <li onClick={handleClick}>
          <a href="#Fisch">Mehr aus dem Meer</a>
        </li>
        {/* <li onClick={handleClick}>
          <a href="#Desserts">Desserts</a>
        </li> */}
      </ul>
    </aside>
  );
};

export default MenuNavigation;

import React from "react";

const GrillInternational = () => {
  return (
    <section id="GrillInternational" className="tab-panel">
      <h2>Grill International</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <h4>74. Schweine-Lachs *</h4>
            </td>
            <td className="preis preis--7">18,50€</td>
            <td className="preis preis--19">19,50€</td>
          </tr>
          <tr>
            <td className="untertext">mit Pommes Frites, Reis und Salat</td>
          </tr>
          {/* <tr>
            <td>
              {" "}
              <h4>75. Schweine-Filet</h4>
            </td>
            <td className="preis preis--7">19,90€</td>
          </tr> */}
          {/* <tr>
            <td className="untertext">mit Beilagen und Salat</td>
          </tr> */}
          <tr>
            <td>
              <h4>77. Schweinefilet-Medaillons</h4>
            </td>
            <td className="preis preis--7">18,90€</td>
            <td className="preis preis--19">18,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit gerösteten Zwiebeln dazu Pommes Frites,
              <br />
              Reis und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>78. Argentinisches Rumpsteak</h4>
            </td>
            <td className="preis preis--7">25,50€</td>
            <td className="preis preis--19">26,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit gerösteten Zwiebeln, Beilagen und Salat
            </td>
          </tr>
          {/* <tr>
            <td>
              <h4>79. Argentinisches Rinderfilet</h4>
            </td>
            <td className="preis preis--7">25,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit hausgemachter Metaxa-Sauce, Beilagen und Salat
            </td>
          </tr> */}
          <tr>
            <td>
              <h4>81. Lammfilets</h4>
            </td>
            <td className="preis preis--7">24,50€</td>
            <td className="preis preis--19">25,90€</td>
          </tr>
          <tr>
            <td className="untertext">mit Beilagen und Salat</td>
          </tr>
          <tr>
            <td>
              <h4>82. Putenbrustfilet *</h4>
            </td>
            <td className="preis preis--7">17,50€</td>
            <td className="preis preis--19">18,50€</td>
          </tr>
          <tr>
            <td className="untertext">mit Pommes Frites, Reis und Salat</td>
          </tr>
          <tr>
            <td>
              <h4>84. Suzukia *</h4>
            </td>
            <td className="preis preis--7">17,90€</td>
            <td className="preis preis--19">18,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              Mettröllchen mit Pommes Frites, Reis und Salat
            </td>
          </tr>
          <tr>
            <td className="menu_info">
              <h5>
                * die gekennzeichneten Gerichte werden mit
                <br />
                der hausgemachten Metaxa-Sauce serviert
              </h5>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default GrillInternational;

import React from "react";

import "../../scss/InfoBlock.scss";

const InfoBlock = (props) => {
  const infoTypeBackground =
    props.infoType === undefined ? "normal" : props.infoType;
  return (
    <div
      className={`InfoBlock__wrapper InfoBlock__wrapper--${infoTypeBackground}`}
    >
      <span className="InfoBlock__title">Information</span>
      <div className="InfoBlock__wrapper--inner">{props.children}</div>
    </div>
  );
};

export default InfoBlock;

import React from "react";

const Beilagen = () => {
  return (
    <section id="Beilagen" className="tab-panel">
      <h2>Beilagen</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <h4>101. Pommes Frites</h4>
            </td>
            <td className="preis preis--7">4,50€</td>
            <td className="preis preis--19">4,50€</td>
          </tr>
          <tr>
            <td className="untertext"></td>
          </tr>
          <tr>
            <td>
              <h4>102. Tomatenreis</h4>
            </td>
            <td className="preis preis--7">5,50€</td>
            <td className="preis preis--19">5,50€</td>
          </tr>
          <tr>
            <td className="untertext"></td>
          </tr>
          <tr>
            <td>
              <h4>103. Folienkartoffel mit Zaziki</h4>
            </td>
            <td className="preis preis--7">5,90€</td>
            <td className="preis preis--19">5,90€</td>
          </tr>
          <tr>
            <td className="untertext"></td>
          </tr>
          <tr>
            <td>
              <h4>104. Kroketten</h4>
            </td>
            <td className="preis preis--7">5,90€</td>
            <td className="preis preis--19">5,90€</td>
          </tr>
          <tr>
            <td className="untertext"></td>
          </tr>
          <tr>
            <td>
              <h4>105. Kretanische Kartoffeln</h4>
            </td>
            <td className="preis preis--7">6,90€</td>
            <td className="preis preis--19">6,90€</td>
          </tr>
          <tr>
            <td className="untertext"></td>
          </tr>
          <tr>
            <td>
              <h4>107. kleiner gemischter Salat</h4>
            </td>
            <td className="preis preis--7">6,90€</td>
            <td className="preis preis--19">6,90€</td>
          </tr>
          <tr>
            <td className="untertext"></td>
          </tr>
          {/* <tr>
            <td>
              {" "}
              <h4>108. pikante Sauce</h4>
            </td>
            <td className="preis preis--7">4,40€</td>
          </tr>
          <tr>
            <td className="untertext"></td>
          </tr> */}
          {/* <tr>
            <td>
              {" "}
              <h4>109. Metaxa-Sauce</h4>
            </td>
            <td className="preis preis--7">4,40€</td>
          </tr>
          <tr>
            <td className="untertext"></td>
          </tr> */}
        </tbody>
      </table>
    </section>
  );
};

export default Beilagen;

import React from "react";

const VorspeisenKalt = () => {
  return (
    <section id="KalteVorspeisen" className="tab-panel active">
      <div className="speisekartenContent__tabletitle">
        <h2>Kalte Vorspeisen</h2>
      </div>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <h4>6. Gebratene Sardellen </h4>
            </td>
            <td className="preis preis--7"> 9,00€</td>
            <td className="preis preis--19"> 9,90€</td>
          </tr>
          <tr>
            <td>
              <h4>8. Oktopus </h4>
            </td>
            <td className="preis preis--7">12,50€</td>
            <td className="preis preis--19">13,50€</td>
          </tr>
          <tr>
            <td className="untertext">in Wein-Zitronen-Sauce</td>
          </tr>
          <tr>
            <td>
              <h4>9. Dolmadakia</h4>
            </td>
            <td className="preis preis--7">7,40€</td>
            <td className="preis preis--19">8,20€</td>
          </tr>
          <tr>
            <td className="untertext">
              vier mit Reis gefüllte Weinblätter in Zitronensauce
            </td>
          </tr>
          <tr>
            <td>
              <h4>10. Zaziki </h4>
            </td>
            <td className="preis preis--7">5,00€</td>
            <td className="preis preis--19">5,30€</td>
          </tr>
          <tr>
            <td className="untertext">pikante Quarkspeise mit Knoblauch</td>
          </tr>
          <tr>
            <td>
              <h4>11. Tarama</h4>
            </td>
            <td className="preis preis--7">5,00€</td>
            <td className="preis preis--19">5,30€</td>
          </tr>
          <tr>
            <td className="untertext">griechische Kaviarcreme</td>
          </tr>
          <tr>
            <td>
              <h4>12. Fetakäse </h4>
            </td>
            <td className="preis preis--7">7,00€</td>
            <td className="preis preis--19">7,50€</td>
          </tr>
          <tr>
            <td className="untertext">garniert mit Zwiebeln und Olivenöl</td>
          </tr>
          <tr>
            <td>
              <h4>13. Mesedakia</h4>
            </td>
            <td className="preis preis--7">15,50€</td>
            <td className="preis preis--19">16,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              verschiedene appetitanregende Spezialitäten
              <br />& 4cl Ouzo mit Eis
            </td>
          </tr>
          {/* <tr>
            <td>
              <h4>14. Marinierter Oktopussalat </h4>
            </td>
            <td className="preis preis--7">12,00€</td>
          </tr> */}
          <tr>
            <td>
              <h4>15. Oliven mit Peperoni </h4>
            </td>
            <td className="preis preis--7">6,90€</td>
            <td className="preis preis--19">7,30€</td>
          </tr>
          <tr>
            <td className="untertext">
              auf Wunsch mit gegrillter Peperoni +0,50€
            </td>
          </tr>
          {/* <tr>
            <td>
              <h4>16. Tyrokafteri </h4>
            </td>
            <td className="preis preis--7">6,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              pikante Creme aus verschiedenen Käsesorten
            </td>
          </tr> */}
        </tbody>
      </table>
    </section>
  );
};

export default VorspeisenKalt;

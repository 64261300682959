import React from "react";

const Pfannengerichte = () => {
  return (
    <section id="Pfannengerichte" className="tab-panel">
      <h2>Lockrufe aus der Pfanne</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <h4>112. Drei Schweine-Lachs</h4>
            </td>
            <td className="preis preis--7">18,90€</td>
            <td className="preis preis--19">19,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit frischen Champignons und Kräutern
              <br />
              in pikanter Weinsauce
            </td>
          </tr>
          <tr>
            <td>
              <h4>113. Geschnetzeltes Putenbrustfilet</h4>
            </td>
            <td className="preis preis--7">18,90€</td>
            <td className="preis preis--19">19,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit frischen Champignons und Kräutern in der
              <br />
              Weinsauce des Hauses zubereitet
            </td>
          </tr>
          <tr>
            <td>
              <h4>114. Geschnetzeltes Schweinefilet</h4>
            </td>
            <td className="preis preis--7">19,90€</td>
            <td className="preis preis--19">20,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit frischen Champignons und Kräutern in
              <br />
              verfeinerter Sauce Hollandaise
            </td>
          </tr>
          <tr>
            <td>
              <h4>115. El Greco-Pfännchen</h4>
            </td>
            <td className="preis preis--7">22,50€</td>
            <td className="preis preis--19">24,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              zarte Rinder- und Schweinemedaillons mit
              <br />
              frischen Champignons und Kräutern in
              <br />
              einer scharf gewürzten Rahmsauce
            </td>
          </tr>
          <tr>
            <td>
              <h4>116. Schweinefilet und Schweinesteakmedaillons</h4>
            </td>
            <td className="preis preis--7">19,90€</td>
            <td className="preis preis--19">20,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit frischen Champignons in Meaxa-sauce
            </td>
          </tr>
          <tr className="nichtVerfugbar">
            <td>
              <h4>117. Taki-Pfännchen</h4>
            </td>
            <td className="preis preis--7">23,50€</td>
            <td className="preis preis--19">25,50€</td>
          </tr>
          <tr className="nichtVerfugbar">
            <td className="untertext">
              Lammfilets mit frischen Champignons und
              <br />
              Kräutern in Sauce Hollandaise und geriebenem Schafskäse
            </td>
          </tr>
          <tr>
            <td>
              <h4>118. Rinderleber</h4>
            </td>
            <td className="preis preis--7">18,90€</td>
            <td className="preis preis--19">19,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit frischen Champignons und Kräutern
              <br />
              in Weinsauce zubereitet
            </td>
          </tr>
          <tr>
            <td>
              <h4>119. Geschnetzeltes Putenbrustfilet</h4>
            </td>
            <td className="preis preis--7">18,90€</td>
            <td className="preis preis--19">19,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              in Currysauce mit frischen Champignons und Kräutern
            </td>
          </tr>
          <tr>
            <td className="menu_info">
              <h5>Zu allen Pfännchengerichten gibt es Butterreis und Brot</h5>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default Pfannengerichte;

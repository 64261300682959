import React from "react";

const VomGrill = () => {
  return (
    <section id="VomGrill" className="tab-panel">
      <h2>Griechische Grillküche</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <h4>58. Artemis-Teller</h4>
            </td>
            <td className="preis preis--7">19,50€</td>
            <td className="preis preis--19">20,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              Gyros, zwei Lammkoteletts
              <br />
              mit Pommes Frites, Reis und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>59. Sokrates-Teller</h4>
            </td>
            <td className="preis preis--7">17,50€</td>
            <td className="preis preis--19">18,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              Gyros, zwei Stück Leber mit gerösteten Zwiebeln,
              <br />
              dazu Pommes Frites, Reis und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>60. El Greco-Teller *</h4>
            </td>
            <td className="preis preis--7">20,50€</td>
            <td className="preis preis--19">21,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              Spieß, Lammkotelett, Suzuki, Schweinefilet
              <br />
              und Gyros, dazu Pommes Frites, Reis und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>61. Schweinelachs-Teller *</h4>
            </td>
            <td className="preis preis--7">17,50€</td>
            <td className="preis preis--19">18,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              zwei kleine Schweinelachs und Gyros
              <br /> mit Pommes Frites, Reis und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>62. Thessaloniki-Teller *</h4>
            </td>
            <td className="preis preis--7">17,50€</td>
            <td className="preis preis--19">18,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              Gyros, Suflaki mit Pommes Frites, Reis und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>63. Korfu-Teller *</h4>
            </td>
            <td className="preis preis--7">17,50€</td>
            <td className="preis preis--19">18,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              ein Suzuki, ein Stück Leber und Gyros
              <br />
              mit Pommes Frites Reis und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>64. Maria-Teller *</h4>
            </td>
            <td className="preis preis--7">20,50€</td>
            <td className="preis preis--19">21,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              Steak, Suzuki, Schweine-Medaillon und Fleischspieß
              <br />
              mit Zaziki auf Folienkartoffel und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>65. Rhodos-Teller *</h4>
            </td>
            <td className="preis preis--7">19,50€</td>
            <td className="preis preis--19">20,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              Gyros,Steak und Schweinefilet
              <br />
              mit Pommes Frites, Reis und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>66. Suflaki *</h4>
            </td>
            <td className="preis preis--7">17,50€</td>
            <td className="preis preis--19">18,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              zwei Fleischspieße mit Pommes Frites, Reis
              <br />
              und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>67. Rinderleber</h4>
            </td>
            <td className="preis preis--7">17,50€</td>
            <td className="preis preis--19">18,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              mit gerösteten Zwiebeln, Zaziki auf
              <br />
              Folienkartoffel und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>68. Bifteki *</h4>
            </td>
            <td className="preis preis--7">16,90€</td>
            <td className="preis preis--19">17,90€</td>
          </tr>
          <tr>
            <td className="untertext">
              Hackfleisch gefüllt mit Schafskäse,
              <br />
              dazu Pommes Frites, Reis und Salat
            </td>
          </tr>
          <tr>
            <td>
              <h4>69. Lammkoteletts</h4>
            </td>
            <td className="preis preis--7">22,50€</td>
            <td className="preis preis--19">24,00€</td>
          </tr>
          <tr>
            <td className="untertext">mit Beilagen und Salat</td>
          </tr>
          {/* <tr>
            <td>
              <h4>70. Lammlachs</h4>
            </td>
            <td className="preis preis--7">23,50€</td>
          </tr>
          <tr>
            <td className="untertext">mit Beilagen und Salat</td>
          </tr>
          <tr>
            <td>
              <h4>71. Taki-Teller</h4>
            </td>
            <td className="preis preis--7">23,50€</td>
          </tr> */}
          {/* <tr>
            <td className="untertext">
              Lammlachs, Lammkotelett und Lammfilet
              <br />
              mit Beilagen und Salat
            </td>
          </tr> */}
          <tr>
            <td>
              <h4>72. Schweinefiletröllchen *</h4>
            </td>
            <td className="preis preis--7">19,50€</td>
            <td className="preis preis--19">20,50€</td>
          </tr>
          <tr>
            <td className="untertext">
              gefüllt mit Schafskäse, Beilagen und Salat
            </td>
          </tr>
          <tr>
            <td className="menu_info">
              <h5>
                * die gekennzeichneten Gerichte werden mit
                <br />
                der hausgemachten Metaxa-Sauce serviert
              </h5>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default VomGrill;
